<template>
    <div class="search-form">
        <div class="search-form__icon-container">
            <div class="search-form__icon">
                <img src="../assets/icons/search.svg" class="arrow-container__image"/>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Search2',

  props: {
  },

  components: {
  },

  data() {
    return {
    };
  },

  computed: {
  },

  methods: {
      
  }
};
</script>

<style lang="scss" scoped>
.search-form {
    display: flex;
    // margin-top: 33px;
    // margin-bottom: 25px;
    text-align: left;
    
    &__icon-container {
        flex: 10;
    }
    &__icon {
        img {
            width: 15px;
            height: 15px;
        }
    }
}
</style>