<template>
    <div class="zem-title">
        <slot />
    </div>
</template>

<script>
export default {
  name: 'ZemTitle',
};
</script>

<style lang="scss" scoped>
.zem-title {
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
    color: $color-mine-shaft;
}
</style>