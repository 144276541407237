<template>
  <label class="zem-checkbox">
    <input v-model="model" :value="inputValue" type="checkbox" @change="e => $emit('change', e)" />
    <span class="checkmark">{{ label }}</span>
    <slot />
  </label>
</template>

<script>
export default {
  props: {
    label: String,
    value: Boolean,
    inputValue: String,
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.zem-checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 25px;
  height: 16px;
  cursor: pointer;
  font-weight: 400;
  font-size: 12px;
  line-height: 13px;
  color: $color-mine-shaft;
  text-align: left;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.zem-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 14px;
  width: 14px;
  background-color: $color-white;
  border: 1px solid $color-alto-gray;
  border-radius: 2px;
}

.zem-checkbox:hover input ~ .checkmark {
  background-color: $color-white;
}

.zem-checkbox input:checked ~ .checkmark {
  background-color: $color-accent;
  border: 1px solid $color-accent;
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.zem-checkbox input:checked ~ .checkmark:after {
  display: block;
}

.zem-checkbox .checkmark:after {
  left: 4px;
  top: 2px;
  width: 4px;
  height: 7px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
</style>
