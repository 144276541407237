<template>
  <div ref="wrapper" :class="{ 'animate' : !show }" class="zem-preloader-wrapper tw-bg-white">
    <div ref="preloader" :class="{ 'hide' : !show }">
      <zem-preloader :size="24" color="primary"/>
    </div>
  </div>
</template>

<script>
import ZemPreloader from "@/components/ui/ZemPreloader.vue";

export default {
  components: {
    ZemPreloader,
  },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  mounted() {
    if (!this.show) this.$refs.wrapper.classList.add('hide')
  },
  watch: {
    show(e) {
      if (!e) {
        this.$refs.preloader.classList.add('hide')
        setTimeout(() => {
          this.$refs.wrapper.classList.add('hide')
        }, 300)
      } else {
        this.$refs.preloader.classList.remove('hide')
        this.$refs.wrapper.classList.remove('hide')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.zem-preloader-wrapper {
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #FFFFFF;
  transition: background-color 0.3s ease-in-out;

  &.animate {
    background-color: #FFFFFF00;
  }
}

.hide {
  display: none;
}
</style>