<template>
  <div
    class="tw-h-full tw-outline tw-outline-offset-1 tw-outline-1 tw-outline-[#d7ebec80] tw-rounded-sm tw-flex tw-items-center tw-py-1.5 tw-px-3 tw-relative"
  >
    <span class="tw-relative tw-z-[1]">{{ row.status ? row.status.data.name : '' }}</span>
    <div
      :class="`tw-w-[${progress}%]`"
      class="tw-h-full tw-rounded-sm tw-bg-[#D7EBEC] tw-absolute tw-top-0 tw-left-0"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    column: {
      type: Object,
      default: () => {},
    },
    row: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    pipelines() {
      return this.$store.state.orders.tree
    },
    progress() {
      if (!this.row.status) return 0
      const pipeline = this.pipelines.find(pipeline => pipeline.id === this.row.pipeline_id)
      const childrenIndex = !!pipeline
        ? pipeline.children.findIndex(children => children.id === this.row.status.data.id) + 1
        : 0
      const numberOfChildren = !!pipeline ? pipeline.children.length - 1 : 0
      return (childrenIndex * 100) / numberOfChildren
    },
  },
}
</script>

<style lang="scss" scoped></style>