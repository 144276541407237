<template>
    <div class="search-form">
        <div class="search-form__info">
            Всего позиции:
        </div>
        <div class="search-form__value">
            12
        </div>
        <div class="search-form__icon-container">
            <div class="search-form__icon">
                <img src="../assets/icons/search.svg" class="arrow-container__image"/>
            </div>
        </div>
    </div>
</template>

<script>
// import Arrow from './ui/Arrow';

export default {
  name: 'Search',

  props: {
  },

  components: {
    //   Arrow,
  },

  data() {
    return {
    };
  },

  computed: {
  },

  methods: {

  }
};
</script>

<style lang="scss" scoped>
.search-form {
    display: flex;
    margin-top: 33px;
    margin-bottom: 25px;
    text-align: left;

    &__info {
        flex: 3;
        font-weight: 400;
        font-size: 12px;
        line-height: 140%;
        color: $color-gull-gray;
    }
    &__value {
        flex: 1;
        font-weight: 400;
        font-size: 12px;
        line-height: 140%;
        color: $color-black;
    }
    &__icon-container {
        flex: 10;
    }
    &__icon {
        img {
            width: 15px;
            height: 15px;
        }
    }
}
</style>
